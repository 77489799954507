var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticStyle:{"margin-top":"32px"},attrs:{"headers":_vm.headers,"items":_vm.workers,"hide-default-footer":"","hide-default-header":"","items-per-page":100,"background-color":"red","no-data-text":"Поки що ви не додали жодного маршруту","loading":_vm.loading},scopedSlots:_vm._u([{key:"header",fn:function({ props }){return _vm._l((props.headers),function(head){return _c('th',{key:head.id,staticStyle:{"padding":"15px"},on:{"click":function($event){!head.sortable && head.sortable !== false
            ? (_vm.sortBy = head.value)((_vm.sortDesc = !_vm.sortDesc))
            : ''}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":head.align ? head.align : 'start'}},[_c('output',{staticStyle:{"font-weight":"500","font-size":"16px","line-height":"22px","letter-spacing":"0.1em","color":"#1b1b1b","cursor":"pointer","user-select":"none"}},[_vm._v(_vm._s(head.text)+" "),(head.sortable !== false)?_c('v-icon',{attrs:{"size":"25px","color":head.value == _vm.sortBy ? 'black' : ''}},[_vm._v("mdi-menu-swap")]):_vm._e()],1)])],1)})}},{key:`item.id`,fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","justify":"center"}}),_c('span',{staticClass:"tableItem"},[_vm._v(_vm._s(item.id))])]}},{key:`item.user`,fn:function({ item }){return [_c('a',{attrs:{"href":`/admin/worker/${item.id}`}},[_c('span',{staticClass:"tableItem",staticStyle:{"color":"#144fa9","cursor":"pointer"}},[_vm._v(_vm._s(item?.first_name)+" "+_vm._s(item?.last_name))])])]}},{key:`item.phone_number`,fn:function({ item }){return [(
          item.phone_number !== 'undefined' && item.phone_number !== 'null'
        )?_c('span',{staticClass:"tableItem"},[_vm._v(_vm._s(item?.phone_number))]):_vm._e()]}},{key:`item.email`,fn:function({ item }){return [_c('a',{attrs:{"href":`mailto:${item.email}`}},[_c('span',{staticClass:"tableItem"},[_vm._v(_vm._s(item?.email))])])]}},{key:`item.status`,fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticStyle:{"width":"8px","height":"8px","border-radius":"50%","margin-right":"8px"},style:(item.status == 'Active'
              ? '  background-color: #149e51;'
              : '  background-color: #DF3A3A')}),_c('div',{staticClass:"statusName",style:(item.status == 'Online' ? '  color: #149e51;' : '  color: #DF3A3A')},[_vm._v(" "+_vm._s(item.status == "Online" ? "Активний" : "Не в мережі")+" ")])])]}},{key:`item.role`,fn:function({ item }){return [_c('span',{staticClass:"tableItem"},[_vm._v(_vm._s(_vm.getRoleName(item?.role_name)))])]}},{key:`item.action`,fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"iconBackground"},[_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"color":"#828282"},on:{"click":function($event){(_vm.symlinks = item.symlinks), (_vm.showDetailSymlinkModal = true)}}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('div',{staticClass:"detailBackground"},[_c('span',[_vm._v("Опції")]),_c('div',{staticStyle:{"border-bottom":"1px solid #f2f2f0","margin-top":"8px"}}),_c('div',{staticStyle:{"padding":"8px 16px","color":"#4b5262","text-align":"left","cursor":"pointer"},on:{"click":function($event){return _vm.$emit('editUser', item)}}},[_vm._v(" Редагувати ")]),_c('div',{staticStyle:{"padding":"8px 16px","color":"#4b5262","text-align":"left","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push(`/admin/worker/${item.id}`)}}},[_vm._v(" Детальніше ")]),_c('div',{staticStyle:{"padding":"8px 16px","color":"#df3a3a","text-align":"left","cursor":"pointer"}},[_vm._v(" Видалити ")])])])],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }