<template>
  <v-col class="px-0 py-0">
    <v-data-table
      style="margin-top: 32px"
      :headers="headers"
      :items="workers"
      hide-default-footer
      hide-default-header
      :items-per-page="100"
      background-color="red"
      no-data-text="Поки що ви не додали жодного маршруту"
      :loading="loading"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            !head.sortable && head.sortable !== false
              ? (sortBy = head.value)((sortDesc = !sortDesc))
              : ''
          "
          v-for="head in props.headers"
          :key="head.id"
          style="padding: 15px"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.1em;
                color: #1b1b1b;
                cursor: pointer;
                user-select: none;
              "
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <v-row no-gutters justify="center"></v-row>
        <span class="tableItem">{{ item.id }}</span>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <a :href="`/admin/worker/${item.id}`"
          ><span class="tableItem" style="color: #144fa9; cursor: pointer"
            >{{ item?.first_name }} {{ item?.last_name }}</span
          ></a
        >
      </template>
      <template v-slot:[`item.phone_number`]="{ item }">
        <span
          class="tableItem"
          v-if="
            item.phone_number !== 'undefined' && item.phone_number !== 'null'
          "
          >{{ item?.phone_number }}</span
        >
      </template>
      <template v-slot:[`item.email`]="{ item }">
        <a :href="`mailto:${item.email}`">
          <span class="tableItem">{{ item?.email }}</span>
        </a>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-row no-gutters align="center">
          <div
            style="
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 8px;
            "
            :style="
              item.status == 'Active'
                ? '  background-color: #149e51;'
                : '  background-color: #DF3A3A'
            "
          />
          <div
            class="statusName"
            :style="
              item.status == 'Online' ? '  color: #149e51;' : '  color: #DF3A3A'
            "
          >
            {{ item.status == "Online" ? "Активний" : "Не в мережі" }}
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        <span class="tableItem">{{ getRoleName(item?.role_name) }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row no-gutters align="center" justify="end">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="iconBackground">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="#828282"
                  class="pointer"
                  @click="
                    (symlinks = item.symlinks), (showDetailSymlinkModal = true)
                  "
                  >mdi-dots-vertical</v-icon
                >
              </div>
            </template>
            <div class="detailBackground">
              <span>Опції</span>
              <div style="border-bottom: 1px solid #f2f2f0; margin-top: 8px" />
              <div
                style="
                  padding: 8px 16px;
                  color: #4b5262;
                  text-align: left;
                  cursor: pointer;
                "
                @click="$emit('editUser', item)"
              >
                Редагувати
              </div>
              <div
                style="
                  padding: 8px 16px;
                  color: #4b5262;
                  text-align: left;
                  cursor: pointer;
                "
                @click="$router.push(`/admin/worker/${item.id}`)"
              >
                Детальніше
              </div>
              <div
                style="
                  padding: 8px 16px;
                  color: #df3a3a;
                  text-align: left;
                  cursor: pointer;
                "
              >
                Видалити
              </div>
            </div>
          </v-menu>
        </v-row>
      </template>
    </v-data-table>
  </v-col>
</template>
  
  <script>
export default {
  data: () => ({
    sortBy: "",
    sortDesc: "",
    headers: [
      {
        text: "№",
        align: "start",
        value: "id",
      },
      // { text: "Статус роботи", value: "status", align: "start" },
      { text: "Користувач", value: "user", align: "start" },
      { text: "Номер телефону", value: "phone_number", align: "start" },
      {
        text: "Електронна адреса",
        value: "email",
        align: "start",
      },
      {
        text: "Посада",
        value: "role",
        align: "center",
      },
      { text: "Опції", value: "action", sortable: false, align: "end" },
    ],
    detailUser: {},
    rolesList: [],
    showDetailUserDrawer: false,
  }),
  props: {
    workers: {
      require: true,
    },
    loading: {
      require: true,
    },
  },
  methods: {
    getRoleName(role) {
      switch (role) {
        case "admin":
          return "Адміністратор";
        case "manager":
          return "Менеджер";
        case "dispatcher":
          return "Диспетчер";
        case "moderator":
          return "Модератор";
        case "quest":
          return "Користувач";
      }
      return "Користувач";
    },
  },
};
</script>
  
  <style scoped>
.statusBox {
  border-radius: 10px;
  background: rgba(20, 158, 81, 0.1);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}
.statusName {
  color: #149e51;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  user-select: none;
}
.editIcon {
  width: 24px;
  height: 24px;
  background-color: #4b5262;
  -webkit-mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat center;
  cursor: pointer;
}
.iconBackground:hover .editIcon {
  background-color: #fafafa;
}
.detailBtn {
  height: 36px;
  padding: 4px 8px;
  border-radius: 10px;
  background: #e7edf6;
  color: #4b5262;
  text-align: center;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-weight: 400;
  display: grid;
  place-items: center;
  cursor: pointer;
  user-select: none;
}
.detailBtn:hover {
  background: #144fa9;
  color: #fafafa;
}
</style>
  <style>
.iconBackground:hover .theme--light.v-icon,
.theme--dark.v-icon {
  color: #fafafa !important;
}
</style>