<template>
  <v-dialog v-model="visibility" width="720px">
    <div class="modalBackground">
      <span>Новий працівник</span>
      <div class="sectionLine" />
      <v-col class="px-0 py-0" v-if="!isNext">
        <v-row no-gutters align="center">
          <v-col cols="6" class="py-0" style="padding-right: 10px">
            <v-text-field
              outlined
              dense
              height="48px"
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              v-model="admin.first_name"
              label="Ім’я"
              placeholder="Введіть тут.."
              :error-messages="firstNameError"
            />
          </v-col>
          <v-col cols="6" class="py-0" style="padding-left: 10px">
            <v-text-field
              outlined
              dense
              height="48px"
              style="border-radius: 10px"
              background-color="#FFF"
              color="#E2E2E2"
              v-model="admin.last_name"
              label="Прізвище"
              placeholder="Введіть тут.."
              :error-messages="lastNameError"
            />
          </v-col>
        </v-row>
        <v-autocomplete
          outlined
          dense
          height="48px"
          style="border-radius: 10px"
          background-color="#FFF"
          color="#E2E2E2"
          v-model="admin.role"
          label="Посада"
          placeholder="Виберіть посаду"
          :items="Object.values(roleList)"
          :item-text="'name'"
          :item-value="'id'"
          :error-messages="roleError"
        />
        <v-text-field
          outlined
          dense
          height="48px"
          style="border-radius: 10px"
          background-color="#FFF"
          color="#E2E2E2"
          v-model="admin.phone_number"
          v-mask="'+###############'"
          label="Номер телефону (необов’язково)"
          placeholder="Введіть тут.."
        />
        <v-text-field
          outlined
          dense
          height="48px"
          style="border-radius: 10px"
          background-color="#FFF"
          color="#E2E2E2"
          v-model="admin.email"
          label="Email"
          placeholder="Введіть тут.."
          :error-messages="emailError"
        />
        <v-text-field
          outlined
          dense
          height="48px"
          style="border-radius: 10px"
          background-color="#FFF"
          color="#E2E2E2"
          v-model="admin.address"
          label="Адреса (необов’язково)"
          placeholder="Введіть тут.."
          autocomplete="address"
        />
        <submit-button
          v-if="!isEdit"
          style="
            height: 48px !important;
            font-size: 20px;
            font-weight: 700;
            margin-top: 20px;
          "
          @click="nextStep"
          >Далі</submit-button
        >
        <submit-button
          v-if="isEdit"
          style="
            height: 48px !important;
            font-size: 20px;
            font-weight: 700;
            margin-top: 20px;
          "
          @click="updateAdmin"
          >Зберегти</submit-button
        >
        <v-row no-gutters justify="center">
          <cancel-btn
            @click="$emit('close')"
            style="margin-top: 10px"
            text="Скасувати"
          />
        </v-row>
      </v-col>
      <v-col class="px-0 py-0" v-else>
        <v-tooltip bottom color="rgba(0, 0, 0, 0.8)" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              color="#164B78"
              :placeholder="'type_here' | localize"
              label="Новий пароль"
              class="rounded-lg"
              v-model="password.password"
              height="48px"
              :type="showNewPassword ? 'text' : 'password'"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showNewPassword = !showNewPassword"
              :error-messages="passwordError"
            >
              <template v-slot:label>
                {{ "new_password_label" | localize
                }}<span class="requireColor">*</span>
              </template>
            </v-text-field>
          </template>
          {{ "password_hint" | localize }}
        </v-tooltip>
        <v-text-field
          outlined
          dense
          color="#164B78"
          :placeholder="'type_here' | localize"
          label="Повторіть пароль"
          class="rounded-lg"
          v-model="password.confirm_password"
          height="48px"
          :type="showConfirmPassword ? 'text' : 'password'"
          :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showConfirmPassword = !showConfirmPassword"
          :error-messages="confirmPasswordError"
        >
          <template v-slot:label>
            {{ "confirm_password_label" | localize
            }}<span class="requireColor">*</span>
          </template>
        </v-text-field>
        <submit-button
          style="
            height: 48px !important;
            font-size: 20px;
            font-weight: 700;
            margin-top: 20px;
          "
          @click="saveAdmin"
          >Підтвердити</submit-button
        >
        <v-row no-gutters justify="center">
          <cancel-btn
            @click="$emit('close')"
            style="margin-top: 10px"
            text="Скасувати"
          />
        </v-row>
      </v-col>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import modalMixin from "../../../mixins/modalMixin";
import usersService from "../../../requests/Admin/usersService";
import CancelBtn from "../../UI/Buttons/cancelBtn.vue";
import submitButton from "../../UI/Buttons/submitButton.vue";
export default {
  components: { submitButton, CancelBtn },
  mixins: [modalMixin, validationMixin],
  data: () => ({
    admin: {
      address: "",
      phone_number: "",
    },
    password: {},
    showNewPassword: false,
    showConfirmPassword: false,
    isNext: false,
    roleList: [
      {
        id: 1,
        name: "Адмін",
      },
      {
        id: 2,
        name: "Менеджер",
      },
      {
        id: 3,
        name: "Диспетчер",
      },
    ],
  }),
  validations: {
    admin: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      role: {
        required,
      },
      email: {
        required,
        email,
      },
    },
    password: {
      password: {
        required,
        minLength: minLength(8),
        validUppercase: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          return containsUppercase;
        },
        validLowercase: function (value) {
          const containsLowercase = /[a-z]/.test(value);
          return containsLowercase;
        },
        validNumber: function (value) {
          const containsNumber = /[0-9]/.test(value);
          return containsNumber;
        },
        validSpecial: function (value) {
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsSpecial;
        },
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial &&
            minLength
          );
        },
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  props: {
    isEdit: {
      require: false,
    },
    userForEdit: {
      require: false,
    },
  },
  mounted() {
    if (this.isEdit) {
      this.setUserForEdit();
    }
  },
  methods: {
    nextStep() {
      this.$v.admin.$touch();
      if (!this.$v.admin.$invalid) {
        this.isNext = true;
      }
    },
    async saveAdmin() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let form = new FormData();
        form.append("first_name", this.admin.first_name);
        form.append("last_name", this.admin.last_name);
        form.append("email", this.admin.email);
        if (this.admin.phone_number !== "") {
          form.append("phone_number", this.admin.phone_number);
        }
        if (this.admin.address !== "") {
          form.append("address", this.admin.address);
        }
        form.append("roles", this.admin.role);
        form.append("password", this.password.password);
        form.append("confirm-password", this.password.confirm_password);
        await usersService.createUser(form).then((res) => {
          if (res.status == "success") {
            this.$emit("successCreate");
          }
        });
      }
    },
    async updateAdmin() {
      this.$v.$touch();
      if (!this.$v.admin.$invalid) {
        let form = new FormData();
        form.append("first_name", this.admin.first_name);
        form.append("last_name", this.admin.last_name);
        form.append("email", this.admin.email);
        if (this.admin.phone_number !== "") {
          form.append("phone_number", this.admin.phone_number);
        }
        if (this.admin.address !== "") {
          form.append("address", this.admin.address);
        }
        form.append("roles", this.admin.role);
        form.append("password", this.password.password);
        form.append("confirm-password", this.password.confirm_password);
        await usersService.updateUser(this.admin.id,form).then((res) => {
          if (res.status == "success") {
            this.$emit("successCreate");
          }
        });
      }
    },
    setUserForEdit() {
      this.$set(this.admin, "id", this.userForEdit.id);
      this.$set(this.admin, "first_name", this.userForEdit.first_name);
      this.$set(this.admin, "last_name", this.userForEdit.last_name);
      this.$set(this.admin, "email", this.userForEdit.email);
      this.$set(this.admin, "phone_number", this.userForEdit.phone_number);
      this.$set(this.admin, "role", this.userForEdit.role_id);
      this.$set(this.admin, "address", this.userForEdit.address);
    },
  },
  computed: {
    firstNameError() {
      const errors = [];
      let field = this.$v.admin.first_name;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    lastNameError() {
      const errors = [];
      let field = this.$v.admin.last_name;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    roleError() {
      const errors = [];
      let field = this.$v.admin.role;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    emailError() {
      const errors = [];
      let field = this.$v.admin.email;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      if (!field.email) {
        errors.push("");
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.password.required) {
        errors.push("Обов'язкове поле");
        return errors;
      }
      if (
        (!this.$v.password.password.valid &&
          this.$v.password.password.required) ||
        (!this.$v.password.password.minLength &&
          this.$v.password.password.required)
      ) {
        errors.push(
          "Пароль повинен містити мінімум 8 символів, серед яких хоч одну цифру, спецсимвол, велику та маленьку літери"
        );
        return errors;
      }
      return errors;
    },
    confirmPasswordError() {
      const errors = [];
      if (!this.$v.password.confirm_password.$dirty) {
        return errors;
      }
      if (!this.$v.password.confirm_password.sameAsPassword) {
        errors.push("Паролі не збігаються");
        return errors;
      }
      !this.$v.password.confirm_password.required &&
        errors.push("Обов'язкове поле");
      return errors;
    },
  },
};
</script>

<style scoped>
.modalBackground {
  background: #fff;
  padding: 16px;
  color: var(--Black, #1b1b1b);
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>