<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center" style="margin-top: 20px">
      <v-col class="px-0 py-0" cols="4">
        <search-field label="Пошук" placeholder="Введіть тут.." />
      </v-col>
      <v-row no-gutters justify="end">
        <submit-button
          style="width: 220px; height: 48px !important"
          @click="showCreateWorkerModal = true"
        >
          <v-icon color="#FAFAFA" style="margin-right: 8px">mdi-plus</v-icon>
          Новий працівник
        </submit-button>
      </v-row>
    </v-row>
    <admins-list
      :workers="workersList"
      :loading="loading"
      @editUser="setUserForEdit"
    />
    <pagination
      :page="page"
      :quantityPage="quantityPage"
      @changePage="changePage"
    />
    <create-worker-modal
      v-if="showCreateWorkerModal"
      :visible="showCreateWorkerModal"
      :isEdit="isEdit"
      :userForEdit="userForEdit"
      @successCreate="successCreate"
      @close="showCreateWorkerModal = false"
    />
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import searchField from "../../UI/Fields/searchField.vue";
import AdminsList from "./adminsList.vue";
import CreateWorkerModal from "./createWorkerModal.vue";
import usersService from "../../../requests/Admin/usersService";
import Pagination from "../../UI/pagination.vue";
export default {
  components: {
    searchField,
    SubmitButton,
    AdminsList,
    CreateWorkerModal,
    Pagination,
  },
  data: () => ({
    workersList: [],
    showCreateWorkerModal: false,
    loading: true,
    userForEdit: {},
    isEdit: false,
    page: 1,
    quantityPage: 0,
  }),
  mounted() {
    this.getWorkers();
  },
  methods: {
    successCreate() {
      this.loading = true;
      this.getWorkers();
      this.showCreateWorkerModal = false;
    },
    changePage(page) {
      this.page = page;
      this.loading = true;
      this.getWorkers();
    },
    async getWorkers() {
      await usersService.getManagers(this.page).then((res) => {
        if (res.status == "Success") {
          this.workersList = res.data;
          this.quantityPage = res.pagination.meta.last_page;
          this.loading = false;
        }
      });
    },
    setUserForEdit(user) {
      this.userForEdit = user;
      this.isEdit = true;
      this.showCreateWorkerModal = true;
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
  },
};
</script>

<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>